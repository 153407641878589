import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { BehaviorSubject } from 'rxjs';
/*import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token
} from '@capacitor/push-notifications';*/
//import { Push, PushObject, PushOptions } from '@ionic-native/push/ngx';
import { environment } from 'src/environments/environment';
import { AlertController } from '@ionic/angular';
import { Router } from '@angular/router';
import { PushService } from './push.service';
import { RestService } from './rest.service';
import { SocketService } from './socket.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  currentAuth: any = null;
  currentUser: any = null;
  botUser: any = null;
  currentUser$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  profileUpdateAttempt: boolean = false;

  userCategories: any[] = []; 
  goToMessages: boolean = false;

  departments: string[] = ['Compras', 'Medio Ambiente', 'Gerencia', 'Logistica', 'Producción', 'Mantenimiento', 'Administración', 'Otros'];

  authError = {
    'auth/user-not-found': 'No existe un usuario con ese correo. O puede que haya sido eliminado.',
    'auth/invalid-email': 'El correo electrónico tiene un formato incorrecto',
    'auth/wrong-password': 'La contraseña ingresada no es correcta.',
    'auth/email-already-exists': 'El correo electrónico ya existe en el sistema',
    'auth/email-already-in-use': 'El correo electrónico ya esta siendo utilizado',
    'err/default': 'Ha ocurrido un error inesperado.'
  };

  constructor(
    private afAuth: AngularFireAuth, 
    private pushService: PushService,
    private router: Router,
    private socketService: SocketService,
    private restService: RestService,
    //private push: Push,
    private alertController: AlertController) {

    this.afAuth.languageCode = Promise.resolve('es_ES');
    this.afAuth.onAuthStateChanged((userAuth) => {
      console.log('Auth changed: ', userAuth);

      this.currentAuth = userAuth;
      this.setupSession(userAuth);
    });

    this.getUserCategories();
  }

  async setupSession(userAuth) {
    let newToken = null;
    let userUID = null;

    if(!!userAuth){
      newToken = await userAuth.getIdToken(true);
      userUID = userAuth.uid;
    }

    this.restService.makeHttpRequest('user', 'getBotUser', {}).subscribe((res) => {
      this.botUser = (!res.error) ? res.userData : null;  
    });  

    this.restService.makeHttpRequest('user', 'setupSession', {
      fireToken: newToken,
      fireAuthUID: userUID
    }).subscribe((res) => {
      console.log('setupSession', res);

      if (!res.error) {
        this.currentUser = res.userData;
        this.currentUser$.next(this.currentUser);

        if (this.currentUser?.id) {

          setTimeout(()=>{
            if (!this.currentUser.desk_push || this.currentUser.desk_push == '') {
              this.showPushAlert();
            }
            else {
              this.requestPushPermission();
            }
          }, 3000);

          this.socketService.setupSocketConnection(this.currentUser.id);

          this.socketService.subscribe(`userUpdated:${this.currentUser.id}`, (data) => {
            console.log('userupdate event from socket', data);
            if (!this.profileUpdateAttempt) {
              //refresh session and current user data
              this.setupSession(this.currentAuth);
            }
            else {
              this.profileUpdateAttempt = false;
            }
          });

          if (this.goToMessages === true || this.router.url == '/login' || this.router.url == '/signup') {
            this.goToMessages = false;
            this.router.navigateByUrl('/messages', { replaceUrl: true });
          }
        }
        else {
          this.socketService.disconnect();
        }
      }
      else {
        this.currentUser = null;
        this.currentUser$.next(this.currentUser);
        
        if (this.currentAuth && this.currentAuth.uid) {
          this.signOut();
        }
      }
    });
  }

  /**
   * Shows an alert for the user session
   */
  async showUserError(text) {
    const alert = await this.alertController.create({
      header: ':(',
      message: text,
      buttons: ['OK'],
    });

    await alert.present();
  }

  /**
   * Registers a new user
   *
   * @param userData The registration form data
   * @returns Promise
   */
   signUp(userData, callback) {

    this.goToMessages = true;

    this.restService.makeHttpRequest('user', 'signUp', userData).subscribe((res) => {
      console.log('uservice', res);

      if (!res.error) {
        callback();

        this.afAuth.signInWithEmailAndPassword(
          userData.email,
          userData.password
        );
      }
      else {
        callback(this.authError[res.code] || this.authError['err/default']);
      }
    });
  }

  /**
   * Sing in user
   * 
   * @param userData The registration form data
   * @returns Promise
   */
  async signIn(userData): Promise<any> {
    this.goToMessages = true;
    return this.afAuth.signInWithEmailAndPassword(
      userData.email,
      userData.password
    ).catch(error => {
      throw new Error(this.authError[error.code] || this.authError['err/default']);
    });
  }

  /**
   * Reset user password
   * 
   * @param email The registration form data
   * @returns Promise
   */
   async resetPassword(email): Promise<any> {
    return this.afAuth.sendPasswordResetEmail(email).catch(error => {
      throw new Error(this.authError[error.code] || this.authError['err/default']);
    });
  }

  signOut() {
    this.afAuth.signOut().then(()=>{
      this.router.navigateByUrl('/login', { replaceUrl: true });
    });
  }

  updateCurrentUser(updateData) {
    this.profileUpdateAttempt = true; //To avoid server update when the changes are local

    this.restService.makeHttpRequest('user', 'updateProfile', {updateData}).subscribe((res) => {
      console.log('updateCurrentUser', res);
      if (!res.error) {
        for(const [key, value] of Object.entries(updateData)) {
          if (['id','uid','email'].indexOf(key) < 0 && this.currentUser.hasOwnProperty(key)) {
            this.currentUser[key] = value;
          }
        }
        this.currentUser$.next(this.currentUser);
      }
    });
  }

  updatePushToken(token) {
    if (token) {
      this.restService.makeHttpRequest('user', 'updatePushToken', {token}).subscribe(res => {
        if (!res.error) {
          console.log('Token updated');
        }
      });
    }
  }

  requestPushPermission() {

    this.pushService.requestPermission().subscribe(
      async token => {
        console.log('PUSH OK', token);

        if (token) {
          this.updatePushToken(token);
          this.initPushNotifications();
        }
      },
      async (err) => {
        console.log('PUSH Token ERR', err);
      }
    );
  }

  async showPushAlert() {
    const alert = await this.alertController.create({
      header: 'Notificaciones',
      message: 'El sitio esta preparado para ofrecer notificaciones, en navegadores que cuenten con soporte para <a href="https://developer.mozilla.org/en-US/docs/Web/API/Push_API" target="_blank">Push API</a>.',
      buttons: [
        { 
          text: 'OK',
          handler: ()=>{
            this.requestPushPermission();
          }
        }
      ]
    });

    await alert.present();
  }

  initPushNotifications(){

    this.pushService.getMessages().subscribe(async (msg: any) => {
      console.log('NEW FG MESSAGE', msg);
      
      const notificationTitle = msg.data.title;
      const notificationOptions = {
        body: msg.data.body,
        icon: msg.data.icon,
        requireInteraction: false,
        persistent: true,
        data: {
          options: {
            action: "default",
            close: true,
            notificationCloseEvent: false,
            url: environment.url + "/messages?chatUid=" + msg.data.chatUid
          }
        }
      };
      
      new Notification(notificationTitle, notificationOptions);
      
      /*const alert = await this.alertController.create({
        header: msg.notification.title,
        subHeader: msg.notification.body,
        message: msg.data.info,
        buttons: ['OK'],
      });
  
      await alert.present();*/
    });
    
    /*const options: PushOptions = {
      android: {},
      ios: {
          alert: 'true',
          badge: true,
          sound: 'false'
      },
      windows: {},
      browser: {
          pushServiceURL: 'http://push.api.phonegap.com/v1/push'
      }
    };

    const pushObject: PushObject = this.push.init(options);

    pushObject.on('notification').subscribe((notification: any) => console.log('Received a notification', notification));

    pushObject.on('registration').subscribe((registration: any) => console.log('Device registered', registration));

    pushObject.on('error').subscribe(error => console.error('Error with Push plugin', error));
    */
  }

/*
    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration',
      (token: Token) => {

        this.updatePushToken(token.value);
        console.log('Push registration', token);
      }
    );

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
      (error: any) => {
        console.log('Error on registration: ' + JSON.stringify(error));
      }
    );

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        console.log('Push received', notification);
      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        console.log('Push action performed', notification);
      }
    );
  }
*/

  getUserCategories(){
    this.restService.makeHttpRequest('user', 'categories', {}, 'get').subscribe((res) => {
      if (!res.error) {
        this.userCategories = res.list;
      }
    });
  }

  getUserList() {
    return this.restService.makeHttpRequest('user', 'list', null, 'get');
  }

  getUser(userId) {
    return this.restService.makeHttpRequest('user', `get/${userId}`, null, 'get');
  }

  updateUser(userId, updateData) {
    return this.restService.makeHttpRequest('user', 'updateUser', {userId, updateData});
  }

  createUser(createData) {
    return this.restService.makeHttpRequest('user', 'createUser', {createData});
  }

  getUserFirstName() {
    if (this.currentUser) {
      return this.currentUser.username.split(',')[0].split(' ')[0];
    }
    else {
      return null;
    }
    
  }

}
