/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class RestService {

  authError = {
    'auth/user-not-found': 'No existe un usuario con ese correo. O puede que haya sido eliminado.',
    'auth/invalid-email': 'El correo electrónico tiene un formato incorrecto',
    'auth/wrong-password': 'La contraseña ingresada no es correcta.',
    'auth/email-already-exists': 'El correo electrónico ya existe en el sistema',
    'auth/email-already-in-use': 'El correo electrónico ya esta siendo utilizado',
    'err/default': 'Ha ocurrido un error inesperado.'
  };

  httpOptions = {
    headers: new HttpHeaders({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Content-Type':  'application/json',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Authorization: '',
      appChannel: 'webAPP',
      platform: 'web',
      appVersion: environment.version
    }),
    withCredentials: true
  };

  constructor(
    private platform: Platform,
    private httpClient: HttpClient) {

  }

  makeHttpRequest(module: string, action: string, params: any, method: string = 'post'): Observable<any> {
    const requestUrl = `${environment.apiUrl}/${module}/${action}`;

    if (method === 'post') {
      return this.httpClient.post(requestUrl, params, this.httpOptions);
    }
    else {
      return this.httpClient.get(requestUrl, {
        headers: this.httpOptions.headers,
        withCredentials: true,
        params
      });
    }
  }

}
