/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { io } from 'socket.io-client';

@Injectable({
  providedIn: 'root'
})
export class SocketService {

  socket;

  constructor() {
  }

  setupSocketConnection(userId) {
    this.socket = io(environment.socketUrl, { 
      query: {
        userId
      }
    });
  }

  subscribe(eventName, listener) {
    this.socket.on(eventName, listener);
  }

  unsubscribe(eventName, listener = null) {
    if (listener) {
      this.socket.off(eventName, listener);
    }
    else {
      this.socket.off(eventName);
    }
  }

  disconnect() {
    if (this.socket) {
      this.socket.off();
      this.socket.disconnect();
    }
  }

}
