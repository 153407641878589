import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserService } from './services/user.service';
import { ModalService } from './services/modal.service';
import { NavigationEnd, Router } from '@angular/router';
import { Platform, AlertController, PopoverController } from '@ionic/angular';
import { BtnsPopoverComponent } from './components/btns-popover/btns-popover.component'
import { Storage } from '@capacitor/storage';
import { SplashScreen } from '@capacitor/splash-screen';
import { App } from '@capacitor/app';
import { SocketService } from './services/socket.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy{
  showSplashScreen = false;
  showIntro = false;
  useDarkTheme = false;
  isOnline = false;
  appSettings = {};
  user: any;
  currentRoute = [];

  constructor(
    public platform: Platform,
    public userService: UserService,
    private socketService: SocketService,
    private modalService: ModalService,
    private alertController: AlertController,
    private popoverController: PopoverController,
    private router: Router
  ) {}

  ngOnInit() {
    
    //subscribe to loggued user changes
    this.subscribeToUser();

    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        console.log(e.url);

        this.currentRoute = e.url.split('?')[0].split('/');
      }
    });

    /*Storage.get({key:'intro_seen'}).then((res)=>{
      if(res.value === null || res.value === 'false'){
        this.showIntroduction();
      }
    });*/

    setTimeout(()=>{
      SplashScreen.hide({
        fadeOutDuration: 300
      });
      this.showSplashScreen = false;
    }, 3000);

    //Set back button handler
    this.platform.backButton.subscribeWithPriority(1, () => {
      this.alertController.create({
        header: 'SALIR',
        message: 'Seguro quieres cerrar la APP?',
        buttons: [
          {
            text: 'NO',
            role: 'cancel',
            cssClass: 'secondary'
          }, {
            text: 'SI',
            handler: () => {
              App.exitApp();
            }
          }
        ],
      }).then((alert) => alert.present());
    });
  }

  ngOnDestroy() {
    this.socketService.disconnect();
  }

  subscribeToUser() {
    this.userService.currentUser$.subscribe(next => {
      this.user = next;
    });
  }

  async attemptLogout() {
    const alert = await this.alertController.create({
      backdropDismiss: false,
      header: 'Cerrar sesión',
      message: 'Seguro quieres cerrar tu sesión?',
      buttons: [
        {
          text: 'CANCELAR',
          role: 'cancel'
        },
        {
        text: 'SI, SEGURO',
        handler: ()=>{
          this.logout();
        }
      }]
    });

    await alert.present();
  }

  logout() {
    this.userService.signOut();
  }

  switchUserAvailability() {

    this.userService.updateCurrentUser({
      is_online: !this.user.is_online
    });
  }

  showIntroduction() {
    this.showIntro = true;
  }

  hideIntroduction(e) {
    this.showIntro = false;
  }

  showContentModal(contentTag) {
    this.modalService.showContent(contentTag);
  }

  async showUserOptions(ev: any) {
    const popover = await this.popoverController.create({
      component: BtnsPopoverComponent,
      componentProps: {
        listElements: [
          {
            text: 'Perfil',
            icon: 'id-card-outline',
            action: () => {
              //this.router.navigateByUrl(['/users', {uid: this.user.uid}]);

              this.router.navigate(['/users'], {queryParams: {profile: this.user.id}});
            }
          },
          {
            text: 'Cerrar sesión',
            icon: 'log-out-outline',
            action: () => {
              this.attemptLogout();
            }
          }
        ]
      },
      cssClass: 'my-custom-class',
      event: ev,
      translucent: true
    });
    await popover.present();

    const { role } = await popover.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
  }

}
