import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ContentModalComponent } from 'src/app/components/content-modal/content-modal.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(private modalCtrl: ModalController) { }

  async showContent(contentTag) {
    console.log('pasa');
    const modal = await this.modalCtrl.create({
      component: ContentModalComponent,
      cssClass: 'my-custom-class',
      componentProps: {
        contentTag
      }
    });
    return await modal.present();
  }
}
