// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  // PROD
  
  production: true,
  url: 'https://asistentecompras.com',
  version: '2.0.0',
  platformType: 'desktop',
  apiUrl: 'https://api.asistentecompras.com:3011',
  socketUrl: 'https://api.asistentecompras.com:3011',
  /*
  // DEV
  production: false,
  url: 'https://localhost:8100',
  version: '2.0.0',
  platformType: 'desktop',
  apiUrl: 'https://localhost:3011',
  socketUrl: 'https://localhost:3011', 
*/
  //PROD DATA
  firebaseConfig: {
    apiKey: "AIzaSyBeR_d6IkwpcDkIe_VbLf4Lcx7zCneYqkA",
    authDomain: "asistentecompras-ec423.firebaseapp.com",
    projectId: "asistentecompras-ec423",
    storageBucket: "asistentecompras-ec423.appspot.com",
    messagingSenderId: "780821781905",
    appId: "1:780821781905:web:5924c25a6b4575261e4e30",
    measurementId: "G-2QFM1R4JTL"
  }

/*  
  //DEV DATA
  firebaseConfig: {
    apiKey: "AIzaSyDZnuSrESdjTmTRdUzyT91dZXa-Gjxwr9w",
    authDomain: "asistentecompras-27b39.firebaseapp.com",
    projectId: "asistentecompras-27b39",
    storageBucket: "asistentecompras-27b39.appspot.com",
    messagingSenderId: "359781607596",
    appId: "1:359781607596:web:7ccccf3cc3ae8c62aa5709"
  }
*/
};

/****
 *  SOCKET EMISION
 * 
 *  userChatCreated:forAgentID {chatId}  --- WHEN user is customer and has no chats
 *  userChatCreated:forContact {chatId}  --- WHEN user creates a new direct chat
 *  userChatUpdated:${cu.user_id}`, {chatId: chatId})  -- ON /chat/updateChat
 *  userChatCreated:forContacts {chatId}  --- ON /updateGroup IF NEW GROUP
 *  userChatUpdated:forContacts {chatId}  --- ON /updateGroup IF EXISTING GROUP
 *  userChatUpdated:forContacts {chatId}  --- ON /createMessage
 *  userNewMessage:forContacts {chatId}  --- ON /createMessage
 *  userChatUpdated:forContacts {chatId}  --- ON /deleteMessages
 * 
 *  'userUpdated', {userId: req.session.user.id} --- ON updateProfile   OWN
 *  userUpdated:{userId: req.session.user.id} --- ON updateProfile   OWN
 * 
 * 'userUpdated', {userId: req.session.user.id} --- ON updateUser   OTHER
 *  userUpdated:{userId: req.session.user.id} --- ON updateUser   OTHER
 * 
 *  userCreated, {userId} --- ON signUp  
 *  userCreated, {userId} --- ON createUser  
 * 
 */


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
