import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-btns-popover',
  templateUrl: './btns-popover.component.html',
  styleUrls: ['./btns-popover.component.scss'],
})
export class BtnsPopoverComponent implements OnInit {
  @Input() listElements;
  @Input() chatData;
  @Input() isAdminSession;
  @Input() groupEditAction;

  constructor(private popoverController: PopoverController) { }

  ngOnInit() {
    console.log('ChatData', this.chatData);
  }

  dismiss() {
    this.popoverController.dismiss();
  }

  callBtnAction(btn) {
    btn.action();
    this.popoverController.dismiss();
  }

  editGroup() {
    this.groupEditAction();
    this.popoverController.dismiss();
  }

  groupUserEnabled(userId) {
    const usr = this.chatData.users.find(u => u.user_id == userId);

    if (usr && usr.enabled) {
      return true;
    }
    return false;
  }

  groupUserDisabled(userId) {
    const usr = this.chatData.users.find(u => u.user_id == userId);

    if (usr && !usr.enabled) {
      return true;
    }
    return false;
  }
}
