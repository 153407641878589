import {Injectable, Injector} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AlertController } from '@ionic/angular';
import { UserService } from './user.service';

@Injectable()
export class RestInterceptor implements HttpInterceptor {

    errorAlertVisible: boolean = false;

    constructor(private alertController: AlertController, private injector: Injector) {
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(request).pipe(
            tap(evt => {
                if (evt instanceof HttpResponse) {

                    if (evt.body && evt.body.error === true) {
                        this.showResponseError(evt.body);
                    }
                }
            }),
            catchError((err: any) => {
                if(err instanceof HttpErrorResponse) {
                    this.showResponseError({ code: 900 });
                }
                return of(err);
            }));
    
    }

    signOut() {
        const uService = this.injector.get(UserService);
        uService.signOut();
    }

    async showResponseError(error: any) {

        if (!this.errorAlertVisible) {
            let msgTitle = 'ERROR!';
            let msgBody = 'Ha habido un error desconocido.';
            let buttons:any[] = [{ 
                text: 'OK'
            }];

            switch (error.code) {
                case 900: // HTTP_ERR
                    msgBody = 'El servicio no responde, compruebe su conexión de internet.';
                    break;
                case 901: // SESSION_CLOSED
                    msgTitle = 'INFORMACIÓN';
                    msgBody = 'La sesión ha caducado. Por favor, vuelva a ingresar.';
                    buttons[0].handler = () => {
                        this.signOut();
                    }
                    break;
                case 902: // ERR_PERMISSION
                    msgBody = 'No tienes permisos suficientes para ejecutar la tarea.';
                    break;
                case 903: // ERR_VERSION
                    msgTitle = 'INFORMACIÓN';
                    msgBody = 'Hay una nueva versión disponible de la aplicación. Es necesario actualizar para obtener la última versión.';
                    buttons[0] = {
                        text: 'ACTUALIZAR',
                        handler: () => {
                            window.location.reload()
                        }
                    }
                    break;
                case 904: // ERR_NO_DATA_PROVIDED
                    msgBody = 'Los datos que has provisto, no son suficientes.';
                    break;
                case 905: // ERR_DATABASE
                    msgBody = 'Error en la base de datos.';
                    break;
                case 906: // ERR_WRONG_USER
                    msgBody = 'Los datos de usuario son incorrectos.';
                    break;
                case 907: // ERR_DISABLED_USER
                    msgBody = 'El usuario se encuentra deshabilitado.';
                    break;
            }

            const alert = await this.alertController.create({
                header: msgTitle,
                message: msgBody,
                backdropDismiss: false,
                buttons
            });

            alert.onDidDismiss().then(() => {
                this.errorAlertVisible = false;
            });

            await alert.present();
            this.errorAlertVisible = true;
        }
    }
}